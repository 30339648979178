<template>
  <div class="registration pb-4">
    <div class="d-flex justify-content-between">
      <h3 class="pb-3 px-1 mb-0">Add Facility Administrator / User</h3>
    </div>
    <AddFacilityUser :customer="2" @submit="create" />
  </div>
</template>
<script>
import AddFacilityUser from "@/containers/Facility/AddFacilityUser";

import { mapActions } from "vuex";
export default {
  components: {
    AddFacilityUser
  },
  name: "FacilityAdmin",
  methods: {
    ...mapActions(["addFacilityUser"]),
    create(payload) {
      this.addFacilityUser(payload);
    }
  }
};
</script>
